
import React from "react";
 import {
   BrowserRouter as Router,
   Switch,
   Route,
   Link
 } from "react-router-dom";
import './App.css';
// import Home from './component/Home';
import Privacy from './component/Privacy';

function App() {
  return (
    <div style={{
      textAlign: "center",
      borderBottom: "solid 1px",
      paddingBottom: "1rem"
    }}>
    <h1>Iziatec - taxi2.0</h1>
    {/* <nav
      style={{
        borderBottom: "solid 1px",
        paddingBottom: "1rem"
      }}
    >
      <Link to="/invoices">Invoices</Link> |{" "}
      <Link to="/expenses">Expenses</Link>
    </nav> */}
  </div>
  );
}

export default App;
