import React from 'react'

export default function Home() {
    return (
        <div>
            <p>Home</p>
        </div>
    )
}

// const styles = StyleSheet.create({})
